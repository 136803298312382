export const rules ={
        name: [
        { required: true, message: "请输入场馆名!", trigger: "blur" },
      ],
      area_id: [
        { required: true, message: "请选择区域", trigger: "change" },
      ],
      grade: [
        { required: true, message: "请选择级别!", trigger: "change" },
      ],
     
    }