<template>
  <div class="container">
    <el-form :inline="true">
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
        <el-button type="success" @click="openAdd">新增</el-button>
      </el-form-item>
    </el-form>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #grade="{ row }">
        <div>
           {{getNameByValue(gradeTypes,row.grade)}}
        </div>
      </template>
      <template #area="{ row }">
        <div>
           {{$getLabel(venueArea,row.area_id,'name','id')}}
        </div>
      </template>
      <template #handler="{ row }">
        <div>
           <el-button type="primary" size="small" @click="openEdit(row)">修改</el-button>
        </div>
      </template>
    </auto-table>

     <AddorEdit ref="add" @success="getList(1)"></AddorEdit>
  </div>
</template>

<script>
import AddorEdit from './AddorEdit.vue';
import {gradeTypes} from '../dict'
export default {
    components:{
        AddorEdit
    },
  data() {
    return {
      gradeTypes,
      DataList: [],
      Option: [
        { name: "名称", value: "name" },
        { name: "区域", value: "area",type: "custom" },
        { name: "级别", value: "grade", type: "custom" },
        { name: "添加时间", value: "create_time" },
        { name: "操作", value: "handler", type: "custom" },
      ],
      Total: 0,
      status: 1,
    };
  },

  mounted() {
     this.id = this.$route.query.id;
   
  },
  computed: {
    venueArea() {
      return this.$store.state.venueArea;
    },
  },

  methods: {
    init(){
      if(this.DataList.length == 0){
        this.getList(1);
      }
 
    },
    openAdd() {
       let comm =  { venue_id: this.id}
          this.$refs.add.open(comm)
    },
     openEdit(row) {
         this.$refs.add.open(row)
    },
    

    //获取列表
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/shared/distributionMap/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          venue_id:this.id
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>