<template>
  <!-- 代理商新增，修改 -->
  <div>
    <el-dialog
      title="编辑"
      :visible.sync="dialogVisible"
      width="50%"
      :close-on-click-modal="false"
    >
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="场地名称" required prop="name">
          <el-input v-model="form.name" placeholder="场地名称"></el-input>
        </el-form-item>
        <el-form-item label="场地类型" required>
          <el-radio v-model="form.mold" label="place">场地</el-radio>
          <el-radio v-model="form.mold" label="equip">器械</el-radio>
        </el-form-item>
        <el-form-item label="预约方式" required>
          <el-radio v-model="form.type" label="person">人数型</el-radio>
          <el-radio v-model="form.type" label="venue">场次型</el-radio>
        </el-form-item>
        <el-form-item label="场地级别" required>
          <el-radio-group v-model="form.grade" @change="gradeChange">
            <el-radio :label="item.value" v-for="(item,i) in gradeTypes" :key="i">{{item.name}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="区域" required>
          <el-select v-model="form.area_id" placeholder="请选择">
            <el-option
              v-for="item in venueArea"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="场地级别" required v-if="form.grade != '1'">
          <el-checkbox-group v-model="sub_device_ids">
            <el-checkbox v-for="(d, i) in baseDevices" :label="d.id" :key="i">{{
              d.name
            }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item label="场地容量" required prop="max_people">
          <el-input
            v-model="form.max_people"
            type="number"
            placeholder="场地容量"
          ></el-input>
          <p v-if="form.type == 'person'">场地最多可同时容纳的人数</p>
          <p v-if="form.type == 'venue'">
            场地最多可同时开放的场次数，如足球场可约场次为1
          </p>
        </el-form-item>
        <el-form-item label="允许教学">
          <el-radio-group v-model="form.teaching">
            <el-radio :label="false">不允许</el-radio>
            <el-radio :label="true">允许</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="闲时共享类目">
          {{ form.share_categories }}
          <el-button type="text" @click="openCate('share_categories')"
            >选择类目</el-button
          >
        </el-form-item>
        <el-form-item label="忙时共享类目">
          {{ form.busy_share_categories }}
          <el-button type="text" @click="openCate('busy_share_categories')"
            >选择类目</el-button
          >
        </el-form-item>
        <el-form-item label="闲时共享价格">
          <el-input
            v-model="form.price"
            type="number"
            placeholder="闲时价格"
          ></el-input>
        </el-form-item>
        <el-form-item label="忙时共享价格">
          <el-input
            v-model="form.big_price"
            type="number"
            placeholder="忙时价格"
          ></el-input>
        </el-form-item>
        <el-form-item label="设备标签">
          <el-input v-model="form.infos" placeholder="设备标签"></el-input>
          多个标签使用,号分隔
        </el-form-item>
        <el-form-item label="说明">
          <el-input
            type="textarea"
            v-model="form.remark"
            placeholder="说明"
          ></el-input>
        </el-form-item>
        <el-form-item label="封面" required>
          <avatar-uploader @getImgUrl="(v) => (form.pic = v)" :url="temp.pic">
          </avatar-uploader>
        </el-form-item>
        <el-form-item label="场地图片">
          <images-uploader
            ref="imgs"
            @getImgUrl="(v) => (form.images = v)"
            :urls="temp.images"
          >
          </images-uploader>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          v-if="form.id"
          type="primary"
          @click="updateForm"
          :loading="loading"
          >更 新</el-button
        >
        <el-button v-else type="primary" :loading="loading" @click="addForm"
          >新 增</el-button
        >
      </span>

      <el-dialog
        width="50%"
        title="选择类目"
        :visible.sync="cateShow"
        append-to-body
      >
        <el-transfer
          filterable
          filter-placeholder="请选择绑定的科目"
          v-model="changeCate"
          :data="categories_list"
          :props="{
            key: 'name',
            label: 'name',
          }"
          :titles="['所有类目', '开放类目']"
        >
        </el-transfer>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="confirmCate">确定</el-button>
        </span>
      </el-dialog>
    </el-dialog>
  </div>
</template>
<script>
import { ObjectChangedValue } from "@/js/utils.js";
import {gradeTypes} from '../dict'
export default {
  data() {
    return {
      gradeTypes,
      rules: {
        name: [
          {
            required: true,
            message: "请填写名称",
            trigger: ["change", "blur"],
          },
        ],
        max_people: [
          {
            required: true,
            message: "请填写最容量",
            trigger: ["change", "blur"],
          },
        ],
      },
      clear: false,
      form: { type: "person" },
      temp: {},
      dialogVisible: false,
      loading: false,
      categories_list: [],
      cateShow: false,
      changeCate: [],
      changeParam: "",
      baseDevices: [],
      sub_device_ids: [],
      venue_id:''
    };
  },

  mounted() {
    this.venue_id = this.$route.query.id
    this.getCategoriesList();
  },

  computed: {
    venueArea() {
      return this.$store.state.venueArea;
    },
  },

  methods: {
    gradeChange(v) {
      if (v !== "1" && this.baseDevices.length == 0) {
        this.getGrade1();
      }
    },
    getGrade1() {
      let data = {
        venue_id: this.venue_id,
        grade: 1,
        pageSize: 50,
      };
      this.$get("/shared/venueDevice/queryListPage", data).then((res) => {
        this.baseDevices = res.data.data;
      });
    },
    openCate(v) {
      this.changeCate = this.form[v] ? this.form[v].split(",") : [];
      this.changeParam = v;
      this.cateShow = true;
    },
    confirmCate() {
      this.form[this.changeParam] = this.changeCate.join(",");
      this.cateShow = false;
    },
    addForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.grade != "1" && this.sub_device_ids.length == 0) {
            this.$message("请选择子场地");
            return;
          }
          this.loading = true;
          this.$axios({
            url: "/shared/venueDevice/insert",
            method: "post",
            data: { ...this.form, sub_ids: this.sub_device_ids },
          })
            .then((res) => {
              this.$notify({
                message: "新增成功",
                duration: 2000,
                type: "success",
              });
              this.dialogVisible = false;
              this.loading = false;
              this.$emit("success", 1);
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },

    updateForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.grade != "1" && this.sub_device_ids.length == 0) {
            this.$message("请选择子场地");
            return;
          }
          const data = ObjectChangedValue(this.temp, this.form);
          if (Object.keys(data).length == 0) {
            this.$message("没有修改数据，无需更新");
            return;
          }
          this.loading = true;
          this.$axios({
            url: "/shared/venueDevice/update",
            method: "post",
            data: {
              id: this.form.id,
              sub_ids: this.sub_device_ids,
              ...data,
            },
          })
            .then((res) => {
              this.$notify({
                message: "更新成功",
                duration: 2000,
                type: "success",
              });
              this.dialogVisible = false;
              this.loading = false;
              this.$emit("success", 1);
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },

    open(info) {
      this.getGrade1();
      if (info && info.id) {
        this.temp = Object.assign({}, info);
        this.form = info;
        this.sub_device_ids = info.sub_ids || [];
      } else {
        this.form = Object.assign({ type: "person", grade: "1" }, info);
        this.temp = {};
        this.sub_device_ids = [];
      }
      this.dialogVisible = true;
    },

    getProductImg(v) {
      this.form.pic = v;
    },
    getCategoriesList() {
      let url = "/user/mechanismCategory/queryListPageChild";
      this.$axios
        .get(url, {
          params: {
            status: 2,
            type: 2,
            source: "space",
          },
        })
        .then((res) => {
          this.categories_list = res.data.data;
        });
    },
  },
};
</script>

<style lang="less" scoped>
</style>