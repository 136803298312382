export const gradeTypes =  [
    
    {
      name: "标准",
      value: '1',
    },
    {
      name: "横场",
      value: '2',
    },
    {
      name: "纵场",
      value: '3',
    },
     {
      name: "大场",
      value: '4',
    }
  ]