<template>
  <div>
    <el-dialog
      :visible.sync="addServiceDialog"
      width="50%"
      title="场地分布图"
      :close-on-click-modal="false"
    >
      <el-form :model="form" label-width="auto" :rules="rules" ref="mapForm">
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入名称"></el-input>
        </el-form-item>
     
      <el-form-item label="分布类型" prop="grade" required>
        <el-radio-group v-model="form.grade">
          <el-radio :label="item.value" v-for="(item,i) in gradeTypes" :key="i">{{item.name}}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="区域" prop="area_id" required>
        <el-select v-model="form.area_id" placeholder="请选择">
          <el-option
            v-for="item in venueArea"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="分布图" ref="imgUpRef" required>
        <avatar-uploader
          @getImgUrl="(v) => (form.img = v)"
          :url="form.img"
        ></avatar-uploader>
      </el-form-item>
       </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addServiceDialog = false">取 消</el-button>
        <el-button v-if="form.id" type="primary" @click="updateForm()"
          >修 改</el-button
        >
        <el-button v-else type="primary" @click="submitForm()">添 加</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
import { rules } from "./addRule";
import { ObjectChangedValue } from "@/js/utils";
import {gradeTypes} from '../dict'
export default {
  data() {
    return {
      gradeTypes,
      form: {},
      temp: {},
      rules: rules,
      addServiceDialog: false,
      venue_id: "",
    };
  },

  mounted() {
    this.venue_id = this.$route.query.id;
  },
  computed: {
    venueArea() {
      return this.$store.state.venueArea;
    },
  },
  methods: {
    open(info) {
      if (info && info.id) {
        this.temp = Object.assign({}, info);
        this.form = info;
      } else {
        this.form = this.temp = Object.assign({}, info);
        this.temp = {};
      }
      this.addServiceDialog = true;
    },
    updateForm() {
      this.$refs.mapForm.validate((valid) => {
        if (valid) {
          this.updateServie();
        }
      });
    },
    submitForm() {
      this.$refs.mapForm.validate((valid) => {
        if (valid) {
          this.addNew();
        }
      });
    },
    addNew() {
      let url = "/shared/distributionMap/insert";
      this.form.venue_id = this.venue_id;
      this.$axios.post(url, this.form).then((res) => {
        this.$message({ type: "warning", message: "添加成功!" });
        this.addServiceDialog = false;
        this.form = {};
        this.$emit("success");
      });
    },
    updateServie() {
      let data = ObjectChangedValue(this.temp, this.form);
      data.id = this.form.id;
      let url = "/shared/distributionMap/update";
      this.$axios.post(url, data).then((res) => {
        this.$message({ type: "success", message: "修改成功!" });
        this.addServiceDialog = false;
        this.form = {};
        this.temp = {};
        this.$emit("success");
      });
    },
  },
};
</script>
  
  <style lang="scss" scoped>
</style>