<template>
  <div>
    <el-dialog :visible.sync="addServiceDialog" width="30%" title="场地区域" :close-on-click-modal="false">
      <el-form
        :model="form"
        label-width="auto"
        :rules="rules"
        ref="courseForm"
      >
      <el-form-item label="区域名称" prop="name">
            <el-input v-model="form.name" placeholder="请输入名称"></el-input>
          </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addServiceDialog = false">取 消</el-button>
         <el-button v-if="form.id"   type="primary" @click="updateForm()">修 改</el-button>
        <el-button v-else type="primary" @click="submitForm()"
          >添 加</el-button
        >
       
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
import { rules } from "./addRule";
import { ObjectChangedValue } from "@/js/utils";
export default {
  
  data() {
    return {
      form: {},
      temp: {},
      rules: rules,
      addServiceDialog: false,
      if_clear: true,
      count: 1,
      cityList: [], 
    };
  },

  mounted() {},

  methods: {
  
    open(info) {
       if (info && info.id) {
        this.temp = Object.assign({}, info);
        this.form = info;
      } else {
        this.form =  this.temp = Object.assign({}, info);
        this.temp = {};
      }
      this.addServiceDialog = true;
      
    },
    updateForm() {
      this.$refs.courseForm.validate((valid) => {
        if (valid) {
          this.updateServie();
        }
      });
    },
    submitForm() {
      this.$refs.courseForm.validate((valid) => {
        if (valid) {
          this.addNew();
        }
      });
    },
    addNew() {
      let url = "/shared/venueArea/insert";

      this.$axios.post(url, this.form).then((res) => {
       this.$message({ type: "warning", message: "添加成功!" });
          this.addServiceDialog = false;
          this.form = {};
          this.$emit("success");
      });
    },
    updateServie() {
      let data = ObjectChangedValue(this.temp, this.form);
      data.id = this.form.id;
      let url = "/shared/venueArea/update";
      this.$axios.post(url, data).then((res) => {
         this.$message({ type: "success", message: "修改成功!" });
          this.addServiceDialog = false;
          this.form = {};
          this.temp = {};
          this.$emit("success");
      });
    }
  },
};
</script>
  
  <style lang="scss" scoped>
</style>